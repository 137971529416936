import React, { useContext, useEffect, useRef, useState } from "react";
import Styles from "./MarketPlacePage.module.scss";
import Button from "@vahak/core-ui/dist/components/Button";
import LoadWhite from "@vahak/core/dist/standard-icons/load-white.svg";
import LorryWhite from "@vahak/core/dist/standard-icons/lorry-white.svg";
import SourceGreenIcon from "@vahak/core/dist/standard-icons/source.svg";
import DestinationRedIcon from "@vahak/core/dist/standard-icons/destination.svg";
import SearchIcon from "@vahak/core/dist/icons/searchWhite.svg";
import { toast } from "@vahak/core/dist/components/toast/toast";

import { MarketPlaceType } from "@vahak/core/dist/constants/market";
import classnames from "classnames";
import SearchInput from "./search-bar/SearchInput";
import { getCityLoadOptionGeneratorMethod } from "../../user-profile/vas-form/method";
import { LocationSearchSource } from "@vahak/core/dist/_services/city-search.service";
import FullScreenLocationInput, {
    FullScreenLocationInputField
} from "./search-bar/mobile-search/FullScreenLocationInput";
import { IMarketplaceConfig, MARKETPLACE_ACTIONS, MarketplaceContext } from "../../../MarketplaceContextProvider";
import AppImg from "../../utils/AppImg";
import { mapPlaceSearchResponseIntoText } from "../../posting-form/common/current-location/UserCurrentLocation";
import LorryTypeFilter from "./filter/LorryTypeFilter";
import { useGetLoadMarketInfiniteQueryV2 } from "../../../services/load/useGetLoadMarketV2/useGetLoadMarketInfiniteQueryV2";
import {
    EXPECTED_AMOUNT_TYPE,
    GA4EventNames,
    IDs,
    LORRY_CATEGORIES_ENUM,
    MY_LOAD_LORRY_TABS,
    NEW_LORRY_TYPES,
    PAYMENT_TYPE_MAP,
    ROUTES,
    STATE_LIST,
    TRUCK_BODY_TYPES
} from "@vahak/core/dist/constants";
import MarketplaceLoadCard, { MarketplaceLoadCardShimmer } from "../load-card/MarketplaceLoadCard";
import { useRouter } from "next/router";
import { LoadMarketCard, LoadMarketParam, LoadMarketType } from "../../../services/load/useGetLoadMarketV2/type";
import { LorryMarketParam } from "../../../services/lorry/useGetLorryMarketV2/type";
import { LorryCardV2, LorryListResponse } from "../../../services/lorry/commonType/type";
import { AppContext } from "@vahak/core/dist/app-context";
import { ADMIN_OPERATION } from "../../../constants/adminOperation";
import { useCsDashboard, useGetCurrentLocationOnPageLoad } from "@vahak/core/dist/hooks";
import { BID_ACTIONS, BiddingFormContext } from "../../../BiddingFormContextProvider";
import { useGetLorryMarketInfiniteQueryV2 } from "../../../services/lorry/useGetLorryMarketV2/useGetLorryMarketInfiniteQueryV2";
import { CompanyServiceType, useEventTrackerService } from "@vahak/core/dist/_services";
import { usePlaceSearchMutation } from "../../../services/usePlaceSearchQuery";
import { GenericObject, PlaceSearchResult } from "@vahak/core/dist/custom-types";
import { capitalize, getCurrentState, getUniqueArray } from "@vahak/core/dist/methods";
import PageMetaTags from "../../common/page-meta-tags/PageMetaTags";
import { breadcrumbsConstants } from "../../../constants/breadcrumbs-constants";
import { getMktContentByCity } from "../../marketplace/content/content";
import ListContainer from "../../marketplace/common/list-container/ListContainer";
import ShowMoreText from "@vahak/core-ui/dist/components/ShowMoreText";
import Typography from "@vahak/core-ui/dist/components/Typography";
import NoActiveLoadsLorries from "../../common/not-found/no-active-loads-lorries/NoActiveLoadsLorries";
import MarketplaceLorryCard from "../lorry-card/MarketplaceLorryCard";
import VahakBenefits from "../../vahak-benefits/VahakBenefits";
import ContentSection from "../../marketplace/content/ContentSection";
import FaqSection from "../../marketplace/content/FaqSection";
import LoadBidFormNew from "../../marketplace/load-bid-form-new/LoadBidFormNew";
import LorryBidForm from "../../marketplace/common/forms/lorry-bid-form/LorryBidForm";
import useValidateUserStatus from "../../../hooks/useValidateUserStatus";
import { POSTING_FORM_MODAL_ORIGINATION_PAGE, useLoadLorryPosting } from "../../../context/load-lorry-posting";
import { useGetLoadMarketV2 } from "../../../services/load/useGetLoadMarketV2/useGetLoadMarketV2";
import { useGetLorryMarketV2 } from "../../../services/lorry/useGetLorryMarketV2/useGetLorryMarketV2";
import { useFeatureByServiceType } from "../../../hooks/useFeatureByServiceType";
import { stringOperations } from "@vahak/core-ui/dist/methods";
import { LOAD_CREATION_FORM_IDS, LORRY_CREATION_FORM_IDS } from "../../posting/helpers/constants";
import { TrackEventHandler } from "../../../types/events";
import { TAG_AGENT_LOAD_PAGE } from "../../../constants/route-constants";
import MarketplacePostingNudgeCard from "../marketplace-posting-nudge-card/MarketplacePostingNudgeCard";
import MandateServiceType from "../../marketplace/mandate-service-type/MandateServiceType";

export function getLorryTypeFromParam(lorryParam: string | string[]) {
    const req = Array.isArray(lorryParam) ? lorryParam : [lorryParam];

    return NEW_LORRY_TYPES.filter((lorryType) => req?.includes(lorryType.label.toLowerCase()));
}

export function botTest(userAgent: string) {
    return /bot|crawler|spider|googlebot|bingbot|msnbot|yandexbot|baiduspider|slurp|duckduckbot/i.test(userAgent);
}

export interface MarketPlacePageProps {
    marketplaceType: string;
    isLoggedIn: boolean;
    isPhoneVerified: boolean | undefined;
    urlSrc: string;
    urlDest: string;
    urlFilters: IMarketplaceConfig | null;
    serviceType: CompanyServiceType | null;
    route?: string;
    isBot?: boolean;
    embeddedWithProfile?: boolean;
}

let shouldOpenBidFormOnPageLoad = false;

const MarketPlacePage = ({
    isLoggedIn: initialIsLoggedInState,
    urlSrc,
    urlDest,
    urlFilters,
    isPhoneVerified: initialPhoneVerifiedState,
    route = "",
    isBot,
    embeddedWithProfile = false,
    ...props
}: MarketPlacePageProps) => {
    const router = useRouter();
    const { sendGAandMoEngageEvent } = useEventTrackerService();
    const { mutateAsync: searchPlaceInfo } = usePlaceSearchMutation();
    const { ValidateLoadBidding, ValidateLorryBidding, ValidateLoadPosting, ValidateLorryPosting } =
        useValidateUserStatus();

    const { checkPermission, isCsDashboardUser } = useCsDashboard();
    const { showLoadFeatures, showLorryFeatures } = useFeatureByServiceType();

    const [shouldExecuteApi, setShouldExecuteApi] = useState<boolean>();
    const [shouldFetchCurrentLocationOnPageLoad, setShouldFetchCurrentLocationOnPageLoad] = useState(false);

    const [selectedLoad, setSelectedLoad] = useState<LoadMarketCard>();
    const [selectedLorry, setSelectedLorry] = useState<LorryCardV2>();

    const [loadData, setLoadData] = useState<LoadMarketType["data"]>();
    const [lorryData, setLorryData] = useState<LorryListResponse["data"]>();

    const [sharedLoad, setSharedLoad] = useState<LoadMarketCard>();
    const [sharedLorry, setSharedLorry] = useState<LorryCardV2>();
    const [trackingDataForSelectedLoad, setTrackingDataForSelectedLoad] = useState<{
        load: LoadMarketCard;
        index: number;
    }>();
    const [trackingDataForSelectedLorry, setTrackingDataForSelectedLorry] = useState<{
        lorry: LorryCardV2;
        index: number;
    }>();

    const lorryDefaultFilter: IMarketplaceConfig = {
        lorryTypeIds: [],
        selectedLorryId: undefined,
        selectLorryNumber: undefined
    };

    const [activeLocationFieldName, setActiveLocationFieldName] = useState<FullScreenLocationInputField>();

    const context = useContext(AppContext);
    const { marketplaceConfig, dispatchMarketplace } = useContext(MarketplaceContext);
    const { bidFormConfig, dispatchBid } = useContext(BiddingFormContext);
    const loadLorryPostingConfig = useLoadLorryPosting();

    const {
        isMobile: isMobileScreen,
        customerCompanyId,
        isPhoneVerified,
        serviceType,
        setShouldPreventLayoutSwitch,
        setServiceType,
        tsoLorrySpecialization = []
    } = context;

    const requiredCompanyId = Number(context?.customerCompanyId || context?.companyId) || undefined;
    const [isLoggedIn, setIsLoggedIn] = useState(Boolean(isPhoneVerified && requiredCompanyId));

    const [activeMarketType, setActiveMarketType] = useState<MarketPlaceType>(
        (!embeddedWithProfile && (router.query.params?.[0] as any as MarketPlaceType)) ||
            (props?.marketplaceType as MarketPlaceType)
    );

    const isTso = checkPermission(ADMIN_OPERATION.tagAgent) && router.pathname.includes("/tso/");

    const isLoadBid = Boolean(router.query["loadbid"]);
    const isLorryBid = Boolean(router.query["lorrybid"]);
    const loadLorryId = router.query["loadbid"] || router.query["lorrybid"];

    /**
     *
     */
    const isLoadMkt = activeMarketType === MarketPlaceType.LOAD;
    const isLorryMkt = activeMarketType === MarketPlaceType.LORRY;

    const actionList = [
        ...(showLoadFeatures
            ? [
                  {
                      id: MarketPlaceType.LOAD,
                      label: "Find Loads",
                      icon: <LoadWhite />
                  }
              ]
            : []),
        ...(showLorryFeatures
            ? [
                  {
                      id: MarketPlaceType.LORRY,
                      label: "Find Lorries",
                      icon: <LorryWhite />
                  }
              ]
            : [])
    ];

    const onChangeMarketPlace = (newMktType: MarketPlaceType) => {
        setActiveMarketType(newMktType);
        const payload: IMarketplaceConfig = {
            ...lorryDefaultFilter,
            marketType: newMktType
        };
        dispatchMarketplace({
            type: MARKETPLACE_ACTIONS.APPLY_FILTER,
            payload
        });
        updateUrl(payload);
    };

    const destRef = useRef<any>(null);

    const onChangeSrcLocation = (val: PlaceSearchResult | undefined) => {
        trackMarketPlaceEvent(GA4EventNames.MARKET_PLACE["marketplace_source_city_entered"], {
            from_city: val?.city,
            from_state: val?.state
        });
        !!val &&
            destRef?.current &&
            setTimeout(() => {
                destRef?.current?.focus();
            }, 0);

        const payload: IMarketplaceConfig = {
            ...marketplaceConfig,
            srcPlace: val,
            srcText: mapPlaceSearchResponseIntoText(val, 1)
        };

        dispatchMarketplace({
            type: MARKETPLACE_ACTIONS.APPLY_FILTER,
            payload
        });
        !val && !marketplaceConfig?.destPlace && updateUrl(payload);
    };
    const onChangeDestLocation = (val: PlaceSearchResult | undefined) => {
        trackMarketPlaceEvent(GA4EventNames.MARKET_PLACE["marketplace_destination_city_entered"], {
            to_city: val?.city,
            to_state: val?.state
        });
        const payload: IMarketplaceConfig = {
            ...marketplaceConfig,
            destPlace: val,
            destText: mapPlaceSearchResponseIntoText(val, 1)
        };
        dispatchMarketplace({
            type: MARKETPLACE_ACTIONS.APPLY_FILTER,
            payload
        });
        !val && !marketplaceConfig?.srcPlace && updateUrl(payload);
    };

    const onClearFilters = () => {
        trackMarketPlaceEvent(GA4EventNames.MARKET_PLACE["filters_cleared"]);
        dispatchMarketplace({ type: MARKETPLACE_ACTIONS.CLEAR_FILTER, payload: {} });
    };

    const openFullScreenFilterPopup = (targetField?: FullScreenLocationInputField) => {
        setActiveLocationFieldName(targetField);
    };

    const isLocationSelected = !!marketplaceConfig?.destPlace || !!marketplaceConfig?.srcPlace;

    // Load marketplace data
    const loadMktPreload = [
        "company_detail",
        "relevancy_tags",
        "total_bids_received",
        ...(isCsDashboardUser ? ["agent_details"] : [])
    ] as LoadMarketParam["preloads"];
    const loadMarketResponse = useGetLoadMarketInfiniteQueryV2(
        {
            lorry_type_counts: true,
            source_place_id: marketplaceConfig?.srcPlace?.place_id,
            destination_place_id: marketplaceConfig?.destPlace?.place_id,
            lorry_type_id: marketplaceConfig?.lorryTypeIds,
            c_i: isTso ? "" : requiredCompanyId,
            preloads: loadMktPreload,
            l: isCsDashboardUser ? 9 : 8,
            isAdmin: !!customerCompanyId
        },
        {
            onSuccess(data) {
                setLoadData(data[0]);
                setShouldExecuteApi(false);
            },
            onError(err) {},
            enabled: isLoadMkt && shouldExecuteApi
        }
    );

    /**
     * Load marketplace data for single load
     * Shared load
     */
    useGetLoadMarketV2(
        {
            preloads: ["company_detail", "bid_infos"],
            isAdmin: isCsDashboardUser,
            loadId: loadLorryId ? [Number(loadLorryId)] : undefined
        },
        {
            onSuccess(data) {
                shouldOpenBidFormOnPageLoad = true;
                data.load_cards.length && setSharedLoad(data.load_cards[0]);
            },
            onError(err) {},
            retry: 0,
            refetchOnWindowFocus: false,
            enabled: isLoadBid && Boolean(loadLorryId)
        }
    );
    //Lorry marketplace data
    const lorryMktPreload = [
        "company_detail",
        ...(isCsDashboardUser ? ["agent_details", "rcdata"] : [])
    ] as LorryMarketParam["preloads"];
    const lorryMarketResponse = useGetLorryMarketInfiniteQueryV2(
        {
            lorry_type_counts: true,
            source_place_id: marketplaceConfig?.srcPlace?.place_id,
            destination_place_id: marketplaceConfig?.destPlace?.place_id,
            lorry_type_id: marketplaceConfig?.lorryTypeIds,
            c_i: isTso ? "" : requiredCompanyId,
            preloads: lorryMktPreload,
            l: isCsDashboardUser ? 9 : 8
        },
        {
            onSuccess: (response) => {
                setLorryData(response[0]);
                setShouldExecuteApi(false);
            },
            onError: () => {},
            enabled: isLorryMkt && shouldExecuteApi
        }
    );

    /**
     * Lorry marketplace data for single load
     * Shared lorry
     */
    useGetLorryMarketV2(
        {
            preloads: ["company_detail", "rcdata"],
            lorryId: loadLorryId ? [Number(loadLorryId)] : undefined
        },
        {
            onSuccess(data) {
                shouldOpenBidFormOnPageLoad = true;
                data.lorry_cards.length && setSharedLorry(data.lorry_cards[0]);
            },
            onError(err) {},
            enabled: isLorryBid && Boolean(loadLorryId)
        }
    );

    const wrapActionWithLoginModal = (cb: Function) => {
        if (requiredCompanyId) {
            cb();
        } else {
            router.push(
                {
                    hash: "Login"
                },
                undefined,
                { shallow: true }
            );
        }
    };

    const updateUrl = async (filterConfig: IMarketplaceConfig, shouldReplace: boolean = true) => {
        if (embeddedWithProfile) {
            setShouldExecuteApi(true);
            return;
        }
        window.scroll({ left: 0, top: 0, behavior: "smooth" });
        const _activeMarket = await getCurrentState(setActiveMarketType);
        const { lorryTypeIds, srcPlace, destPlace } = { ...filterConfig };

        const srcParam = srcPlace ? mapPlaceSearchResponseIntoText(srcPlace, 2) : "";
        const destParam = destPlace ? mapPlaceSearchResponseIntoText(destPlace, 2) : "";
        const tempObj: any = router.query;
        if (lorryTypeIds?.length) {
            const l = NEW_LORRY_TYPES.filter((v) => lorryTypeIds?.includes(v.id))?.map((v) => v.label?.toLowerCase());
            tempObj["l"] = l;
        } else if (tempObj.l) {
            delete tempObj.l;
        }

        let locationParam: string | undefined = undefined;
        if (srcParam && destParam) {
            locationParam = `${srcParam}-to-${destParam}`;
        } else if (srcParam) {
            locationParam = srcParam;
        } else if (destParam) {
            locationParam = `to-${destParam}`;
        }
        let arr: string[] = [...(router.query.params as string[])];
        arr[0] = _activeMarket;
        if (locationParam) {
            arr[1] = locationParam?.replace?.(/ /g, "-")?.replace?.(/,/g, "-")?.replace?.(/--/g, "-")?.toLowerCase?.();
        } else {
            arr = arr.slice(0, 1);
        }
        router.query.params = arr;
        const method = shouldReplace ? router.replace : router.push;
        await method(
            {
                pathname: router.pathname,
                query: { ...router.query, ...tempObj },
                hash: !requiredCompanyId ? location.hash : undefined
            },
            undefined,
            { shallow: true }
        );
        setShouldExecuteApi(true);
    };

    const trackingEventAutoCalculatedData = {
        source_screen: isLoadMkt ? "load_market" : "lorry_market",
        filter: isLoadMkt ? "load_market" : "lorry_market",
        from_city: marketplaceConfig?.srcPlace?.city,
        from_state: marketplaceConfig?.srcPlace?.state,
        to_city: marketplaceConfig?.destPlace?.city,
        to_state: marketplaceConfig?.destPlace?.state,
        ...(isTso &&
            TAG_AGENT_LOAD_PAGE === route && {
                source_screen: isLoadMkt ? "tso_load_market" : "tso_lorry_market"
            })
    };

    const trackMarketPlaceEvent: TrackEventHandler = (eventName, data, trackGa = true, trackMo = true) => {
        sendGAandMoEngageEvent(
            {
                name: eventName,
                data: {
                    ...trackingEventAutoCalculatedData,
                    ...data
                }
            },
            trackGa,
            trackMo
        );
    };

    const trackLoadBid = (name: string, ld: LoadMarketCard, index: number, additionalData?: GenericObject) => {
        sendGAandMoEngageEvent({
            name: name,
            data: {
                source_screen: "load_market",
                bid_type: "load_bid",
                rank: index,
                ...getLoadTrackData(ld),
                ...(additionalData && additionalData)
            }
        });
    };

    const trackLorryBid = (name: string, lr: LorryCardV2, index: number, additionalData?: GenericObject) => {
        sendGAandMoEngageEvent({
            name: name,
            data: {
                source_screen: "lorry_market",
                bid_type: "lorry_bid",
                rank: index,
                ...getLorryTrackData(lr),
                ...(additionalData && additionalData)
            }
        });
    };

    const onClickLoadBid = async (loadBidData: LoadMarketCard, ix: number) => {
        const onSuccess = () => {
            (loadBidData?.load_detail?.is_tap_n_go || loadBidData?.load_detail?.is_spot_load) &&
                router.replace(ROUTES.myLoadsMyLorries.getUrl("lorries", "bids"), undefined, { shallow: true });

            setLoadData((data) => {
                return {
                    page_number: 0,
                    total_pages: 0,
                    total_records: 0,
                    ...data,
                    load_cards: data?.load_cards?.slice(ix, 1) ?? []
                };
            });
        };

        !Boolean(requiredCompanyId) && setShouldPreventLayoutSwitch?.(true);

        const handleBidding = () => {
            let reset = false;
            if (loadBidData.load_detail?.id !== selectedLoad?.load_detail.id) {
                reset = true;
                setSelectedLoad(loadBidData);
            }
            dispatchBid({
                type: BID_ACTIONS.LOAD,
                payload: { id: loadBidData.load_detail?.id, open: true, reset, data: loadBidData, onSuccess }
            });
        };
        if (Boolean(requiredCompanyId)) {
            ValidateLoadBidding?.(handleBidding);
        } else {
            ValidateLoadBidding(undefined, !!requiredCompanyId);
        }
    };

    const onClickLorryBid = async (item: LorryCardV2, ix: number) => {
        if (!requiredCompanyId) {
            return ValidateLorryBidding();
        }
        // !Boolean(requiredCompanyId) && setShouldPreventLayoutSwitch?.(true);
        // const onSuccess = () => {
        //     setLorryData((data) => {
        //         return {
        //             page_number: 0,
        //             total_pages: 0,
        //             total_records: 0,
        //             ...data,
        //             lorry_cards: data?.lorry_cards?.slice(ix, 1) ?? []
        //         };
        //     });
        // };
        // ValidateLorryBidding?.(() => {
        //     let reset = false;

        //     if (item.lorry_detail?.id !== selectedLorry?.lorry_detail?.id) {
        //         reset = true;
        //         setSelectedLorry(item);
        //     }
        //     dispatchBid({
        //         type: BID_ACTIONS.LORRY,
        //         payload: { id: item.lorry_detail?.id, open: true, reset, data: item, onSuccess }
        //     });
        //     updateUrl(marketplaceConfig);
        // });
    };

    useEffect(() => {
        if (!showLoadFeatures && !showLorryFeatures && isPhoneVerified && requiredCompanyId && !isCsDashboardUser) {
            const pathname = ROUTES.myLoadsMyLorries.getUrl(
                serviceType === CompanyServiceType.FLEET_OWNER ? "lorries" : "loads",
                MY_LOAD_LORRY_TABS.LISTING
            );
            router.replace(
                {
                    pathname: pathname,
                    hash: undefined
                },
                undefined,
                { shallow: true }
            );
        }
    }, [showLoadFeatures, showLorryFeatures]);

    useEffect(() => {
        (async () => {
            let lorryTypeIds = urlFilters?.lorryTypeIds ?? [];
            let payload: IMarketplaceConfig = { ...marketplaceConfig };
            if (isTso && Array.isArray(tsoLorrySpecialization)) {
                lorryTypeIds = getUniqueArray([...lorryTypeIds, ...tsoLorrySpecialization]);
            }
            if (lorryTypeIds?.length) {
                payload = {
                    ...payload,
                    lorryTypeIds
                };
            }
            if (urlSrc) {
                const { data: srcInfo } = await searchPlaceInfo({
                    query: urlSrc,
                    option: {
                        allowState: true,
                        source: isLoadMkt
                            ? LocationSearchSource.LoadMarketPlaceSearchSource
                            : LocationSearchSource.LorryMarketPlaceSearchSource
                    }
                });
                if (srcInfo?.length) {
                    payload = {
                        ...payload,
                        srcPlace: srcInfo?.[0],
                        srcText: mapPlaceSearchResponseIntoText(srcInfo?.[0], 1)
                    };
                }
            }
            if (urlDest) {
                const { data: destInfo } = await searchPlaceInfo({
                    query: urlDest,
                    option: {
                        allowState: true,
                        source: isLoadMkt
                            ? LocationSearchSource.LoadMarketPlaceSearchDestination
                            : LocationSearchSource.LorryMarketPlaceSearchDestination
                    }
                });
                if (destInfo?.length) {
                    payload = {
                        ...payload,
                        destPlace: destInfo?.[0],
                        destText: mapPlaceSearchResponseIntoText(destInfo?.[0], 1)
                    };
                }
            }
            dispatchMarketplace({ type: MARKETPLACE_ACTIONS.APPLY_FILTER, payload });

            if (loadLorryId) {
                shouldOpenBidFormOnPageLoad = true;
            }
            /**
             * Work around to get updated context values to check if the user is logged in or not
             */
            if (
                Boolean(initialPhoneVerifiedState && initialIsLoggedInState) &&
                Boolean(isPhoneVerified && requiredCompanyId)
            ) {
                setIsLoggedIn(true);
            }
            if (!urlSrc && !urlDest && !isCsDashboardUser) {
                setShouldFetchCurrentLocationOnPageLoad(true);
            } else {
                updateUrl(payload);
            }
        })();
    }, []);

    const isLoginModalOpen = router.asPath.split("#")[1]?.includes("Login");
    useEffect(() => {
        if (!router.pathname.includes("/tso/")) {
            if (serviceType === CompanyServiceType.SHIPPER) {
                onChangeMarketPlace(MarketPlaceType.LORRY);
            } else if (serviceType === CompanyServiceType.FLEET_OWNER) {
                onChangeMarketPlace(MarketPlaceType.LOAD);
            }
        }
    }, [serviceType]);

    useEffect(() => {
        if (requiredCompanyId && isLoginModalOpen) {
            setShouldPreventLayoutSwitch?.(true);
        } else if (requiredCompanyId) {
            setShouldPreventLayoutSwitch?.(false);
        }
    }, [requiredCompanyId, isLoginModalOpen]);
    useEffect(() => {
        // SUDH to check
        if (isTso && props?.marketplaceType) {
            setActiveMarketType(props?.marketplaceType as MarketPlaceType);
            setShouldExecuteApi(true);
        }
    }, [props?.marketplaceType]);

    useEffect(() => {
        if (isPhoneVerified !== initialPhoneVerifiedState || activeMarketType !== props?.marketplaceType) {
            updateUrl(marketplaceConfig);
        }
    }, [isPhoneVerified, activeMarketType]);

    useEffect(() => {
        (async () => {
            if (sharedLoad || sharedLorry) {
                if (!!loadLorryId && !requiredCompanyId) {
                    setServiceType?.(CompanyServiceType.TRANSPORT_CONTRACTOR);
                    setActiveMarketType(isLoadBid ? MarketPlaceType.LOAD : MarketPlaceType.LORRY);
                    updateUrl(marketplaceConfig);
                }
                if (!shouldOpenBidFormOnPageLoad) {
                    return;
                }
                if (activeMarketType === "load" && isLoadBid) {
                    if (sharedLoad?.load_detail?.company_id != requiredCompanyId) {
                        if (!!requiredCompanyId)
                            ValidateLoadBidding?.(() => {
                                setSelectedLoad(sharedLoad);
                                dispatchBid({
                                    type: BID_ACTIONS.LOAD,
                                    payload: {
                                        id: sharedLoad?.load_detail.id,
                                        open: true,
                                        reset: true,
                                        data: sharedLoad
                                    }
                                });
                            });
                        else {
                            setSelectedLoad(sharedLoad);
                            dispatchBid({
                                type: BID_ACTIONS.LOAD,
                                payload: {
                                    id: sharedLoad?.load_detail.id,
                                    open: true,
                                    reset: true,
                                    data: sharedLoad
                                }
                            });
                            !Boolean(requiredCompanyId) && setShouldPreventLayoutSwitch?.(true);
                        }
                    } else {
                        dispatchBid({
                            type: BID_ACTIONS.LOAD,
                            payload: { id: sharedLoad?.load_detail.id, open: false, reset: false }
                        });
                        toast.warning("You can't Bid on this load");
                    }
                }
                if ((await getCurrentState(setActiveMarketType)) === "lorry" && isLorryBid) {
                    if (sharedLorry?.company_detail?.id != requiredCompanyId) {
                        ValidateLorryBidding?.(() => {
                            setSelectedLorry(sharedLorry);
                            dispatchBid({
                                type: BID_ACTIONS.LORRY,
                                payload: {
                                    id: sharedLorry?.lorry_detail?.id,
                                    open: true,
                                    reset: true,
                                    data: sharedLorry
                                }
                            });
                        });
                    } else {
                        dispatchBid({
                            type: BID_ACTIONS.LORRY,
                            payload: { id: sharedLorry?.lorry_detail?.id, open: false, reset: false }
                        });
                        toast.warning("You can't Bid on this lorry");
                    }
                }
                shouldOpenBidFormOnPageLoad = false;
            }
        })();
    }, [sharedLoad, sharedLorry, isPhoneVerified, loadLorryId]);

    const currLoc = useGetCurrentLocationOnPageLoad({
        enabled: shouldFetchCurrentLocationOnPageLoad,
        locationSearchParams: {
            source: LocationSearchSource.CompanyCurrentLocation
        }
    });

    useEffect(() => {
        if (shouldFetchCurrentLocationOnPageLoad) {
            let payload: IMarketplaceConfig = { ...marketplaceConfig };

            if (currLoc?.place_id) {
                payload = {
                    ...payload,
                    srcPlace: currLoc,
                    srcText: mapPlaceSearchResponseIntoText(currLoc, 1)
                };
                dispatchMarketplace({
                    type: MARKETPLACE_ACTIONS.APPLY_FILTER,
                    payload
                });
                setShouldFetchCurrentLocationOnPageLoad(false);
            }
            updateUrl(payload);
        }
    }, [shouldFetchCurrentLocationOnPageLoad, currLoc]);

    const isMkt =
        router.pathname.includes("/marketplace/") ||
        router.pathname.includes("/customer-marketplace/") ||
        (isTso && router.pathname.includes("/market/"));

    const getCityInfoMeta = () => {
        const { query } = router;
        const city =
            (isMkt ? (query.params?.[1] ? capitalize(query.params?.[1]) : "") : marketplaceConfig?.srcText) ?? "";
        const CITY_TEXT_WITHOUT_INDIA = city
            .replace("-india", "")
            .replace(/-/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
        const IN_CITY_TEXT = `${city ? "in " + city : ""}`;
        const IN_CITY_TEXT_WITHOUT_INDIA = `${city ? "in " + CITY_TEXT_WITHOUT_INDIA : ""}`;
        const META_TITLE = isLoadMkt
            ? city
                ? `Find Truck Load Online in ${city} - India's Top Transport Marketplace`
                : `Find Instant Truck Load Online - India's Top Transport Marketplace`
            : city
            ? `Find Online Truck Booking in ${city} - India's Top Marketplace`
            : `Book Your Truck Online : India's Top Lorry Booking Marketplace`;

        const META_DESCRIPTION = isLoadMkt
            ? `Book truck loads online in ${city} with ease. Our online platform offers GST and Aadhaar Verified Transport Services, truck owners and agents at your convenience. Book now.`
            : `Find instant truck, load, and lorry bookings online in ${city} on India’s largest transport marketplace. Streamline your logistics with ease. Register & Bid Now!`;
        const META_KEYWORDS = isLoadMkt
            ? `truck load booking near me, online load booking ${IN_CITY_TEXT}, load booking ${city}, find truck loads ${IN_CITY_TEXT}, find load for my truck ${IN_CITY_TEXT}, how to find load for truck, truck attachment ${IN_CITY_TEXT}`
            : `truck booking ${city}, online truck booking ${IN_CITY_TEXT}, truck booking app ${city}, mini truck transport ${IN_CITY_TEXT}, online mini truck booking ${city}`;

        return {
            city,
            CITY_TEXT_WITHOUT_INDIA,
            IN_CITY_TEXT,
            IN_CITY_TEXT_WITHOUT_INDIA,
            META_TITLE,
            META_DESCRIPTION,
            META_KEYWORDS
        };
    };

    const LocationSearchSlot = (
        <div className={Styles.locationForm}>
            <div className={Styles.locationInputWrapper}>
                <div className={Styles.locationContainer}>
                    <SearchInput
                        id="src-search"
                        containerIcon={<SourceGreenIcon />}
                        placeholder="Add Pickup Location"
                        loadOptions={getCityLoadOptionGeneratorMethod({
                            source: LocationSearchSource.LoadMarketPlaceSearchDestination
                        })}
                        value={marketplaceConfig?.srcPlace}
                        valueText={marketplaceConfig?.srcText}
                        onChange={onChangeSrcLocation}
                        showCurrentLocation={!isMobileScreen}
                        {...(isMobileScreen && {
                            placeholder: "Add Pickup",
                            openPopUpView: openFullScreenFilterPopup?.bind(undefined, "src")
                        })}
                    />
                </div>
                <div className={Styles.locationContainer}>
                    <SearchInput
                        ref={destRef}
                        id="dest-search"
                        containerIcon={<DestinationRedIcon />}
                        placeholder="Add Drop Location"
                        loadOptions={getCityLoadOptionGeneratorMethod({
                            source: LocationSearchSource.LoadMarketPlaceSearchDestination
                        })}
                        value={marketplaceConfig?.destPlace}
                        valueText={marketplaceConfig?.destText}
                        onChange={onChangeDestLocation}
                        {...(isMobileScreen && {
                            placeholder: "Add Drop",
                            openPopUpView: openFullScreenFilterPopup?.bind(undefined, "dest")
                        })}
                    />
                </div>
            </div>
            <Button
                startIcon={<SearchIcon />}
                blockBtn
                isRounded
                {...(isMobileScreen && {
                    size: "s"
                })}
                disabled={!isLocationSelected}
                id="mkt-search"
                onClick={(_) => {
                    wrapActionWithLoginModal(() => updateUrl(marketplaceConfig));
                    trackMarketPlaceEvent(GA4EventNames.MARKET_PLACE["marketplace_search_clicked"]);
                }}
            >
                Search
            </Button>

            {isMobileScreen && !!activeLocationFieldName && (
                <FullScreenLocationInput
                    companyId={requiredCompanyId}
                    onToggle={() => {
                        !marketplaceConfig?.srcPlace && !marketplaceConfig?.destPlace && updateUrl(marketplaceConfig);
                        openFullScreenFilterPopup?.();
                    }}
                    filters={marketplaceConfig}
                    onApplyFilters={(filters) => {
                        dispatchMarketplace({ type: MARKETPLACE_ACTIONS.APPLY_FILTER, payload: filters });
                    }}
                    marketType={activeMarketType}
                    activeField={activeLocationFieldName}
                    onClearFilter={onClearFilters}
                    onSubmit={() => {
                        trackMarketPlaceEvent(GA4EventNames.MARKET_PLACE["marketplace_search_clicked"]);
                        wrapActionWithLoginModal(() => {
                            openFullScreenFilterPopup();
                            updateUrl(marketplaceConfig);
                        });
                    }}
                    lorryCount={loadMarketResponse?.data?.pages?.[0]?.data?.load_aggregation_response?.lorry_type_id}
                    trackEvent={trackMarketPlaceEvent}
                />
            )}
        </div>
    );
    const LorrySelectionSlot = (
        <div className={Styles.lorrySelection}>
            <LorryTypeFilter
                trackEvent={trackMarketPlaceEvent}
                companyId={requiredCompanyId}
                showPostedLorryList={
                    !isTso &&
                    !!serviceType &&
                    [CompanyServiceType.TRANSPORT_CONTRACTOR, CompanyServiceType.FLEET_OWNER].includes(
                        serviceType as CompanyServiceType.TRANSPORT_CONTRACTOR
                    ) &&
                    isPhoneVerified &&
                    isLoadMkt
                }
                filters={marketplaceConfig}
                onApplyFilters={(f = {}) => {
                    wrapActionWithLoginModal(() => {
                        dispatchMarketplace({ type: MARKETPLACE_ACTIONS.APPLY_FILTER, payload: { ...f } });
                        updateUrl(f);
                    });
                }}
                lorryCount={
                    isLoadMkt
                        ? loadMarketResponse?.data?.pages?.[0]?.data?.load_aggregation_response?.lorry_type_id
                        : lorryMarketResponse?.data?.pages?.[0].data?.lorry_aggregation_response?.lorry_type_id
                }
            />
        </div>
    );

    const cityMeta = getCityInfoMeta();

    const PageMeta = (
        <PageMetaTags
            title={cityMeta.META_TITLE}
            description={cityMeta.META_DESCRIPTION}
            keywords={cityMeta.META_KEYWORDS}
            canonicalUrl={`https://www.vahak.in${ROUTES.marketplace.getUrl(activeMarketType, urlSrc, urlDest)}`}
            ogUrl={`https://www.vahak.in${ROUTES.marketplace.getUrl(activeMarketType, urlSrc, urlDest)}`}
            ogImgSrc="https://d1rgemtytdlz2g.cloudfront.net/vahak-timeline_gvlxfa.png"
            ogImgAltText={`Search for ${isLoadMkt ? "Transport Goods, Truck Load" : "Truck, Lorries"} in ${
                router.query.params?.[1]
            } at your fingertips! Vahak is India's #1 marketplace for online Truck booking  and Truck Loads. Connect with GST and Aadhaar verified transport services , truck owners and agents.`}
            schemaOrgJsonUrl={ROUTES.marketplace.getUrl(activeMarketType, urlSrc, urlDest)}
            breadcrumbList={breadcrumbsConstants({ mktType: activeMarketType }).marketplace}
            faqList={getMktContentByCity(cityMeta.IN_CITY_TEXT_WITHOUT_INDIA, isLoadMkt).faqList}
        />
    );

    if (!embeddedWithProfile && isMobileScreen === undefined) {
        return <></>;
    }

    const NudgeCardPosition = isMobileScreen ? 1 : 5;

    return (
        <>
            <span />
            {PageMeta}

            <div
                className={classnames(
                    Styles.main,
                    initialPhoneVerifiedState && !initialIsLoggedInState && Styles.companyNotRegistered,
                    (isLoggedIn || isTso) && Styles.isLoggedIn,
                    embeddedWithProfile && Styles.embeddedWithProfile
                )}
            >
                <div
                    className={classnames(Styles.header, !isMkt && Styles.noBackground, !isMkt && Styles.noPaddedBody)}
                >
                    <div className={Styles.aligner}>
                        {isMkt && (
                            <div className={Styles.coverRow}>
                                <div className={Styles.textBox}>
                                    <h1>{`Find Online ${isLoadMkt ? "Load" : "Lorry"} Booking ${
                                        cityMeta.IN_CITY_TEXT_WITHOUT_INDIA
                                    }`}</h1>
                                </div>
                                <div className={Styles.imageBox}>
                                    <AppImg
                                        src={`/mkt-banner-v3-${isLoadMkt ? "load" : "lorry"}.svg`}
                                        alt={`Find Online ${isLoadMkt ? "Load" : "Lorry"} Booking ${
                                            cityMeta.IN_CITY_TEXT_WITHOUT_INDIA
                                        }`}
                                        width="374"
                                        height={isLoadMkt ? 200 : 190}
                                        unoptimized
                                        unselectable="on"
                                    />
                                </div>
                            </div>
                        )}
                        <div className={Styles.searchWrapper}>
                            {(isMobileScreen ? actionList?.length === 2 : !!actionList?.length) && (
                                <div className={Styles.ctaWrapper}>
                                    {actionList.map((item) => {
                                        return (
                                            <span
                                                key={item.id}
                                                className={classnames(
                                                    Styles.cta,
                                                    activeMarketType === item.id && Styles.active
                                                )}
                                            >
                                                <Button
                                                    id={`mktHeader_btn_${item?.id}`}
                                                    startIcon={item?.icon}
                                                    onClick={() => {
                                                        trackMarketPlaceEvent(
                                                            item.id == MarketPlaceType.LOAD
                                                                ? GA4EventNames.MARKET_PLACE[
                                                                      "marketplace_load_market_selected"
                                                                  ]
                                                                : GA4EventNames.MARKET_PLACE[
                                                                      "marketplace_lorry_market_selected"
                                                                  ]
                                                        );
                                                        onChangeMarketPlace(item.id);
                                                    }}
                                                    mSize="sm"
                                                >
                                                    {item?.label}
                                                </Button>
                                            </span>
                                        );
                                    })}
                                </div>
                            )}

                            {LocationSearchSlot}
                            {LorrySelectionSlot}
                        </div>
                    </div>
                </div>
                <div className={classnames(Styles.body, !isMkt && Styles.noPaddedBody)}>
                    <ListContainer
                        title={`Available ${isLoadMkt ? "Loads" : "Lorries"} ${cityMeta.IN_CITY_TEXT_WITHOUT_INDIA} ${
                            isLoadMkt
                                ? loadData?.total_records
                                    ? "(" + loadData?.total_records + ")"
                                    : ""
                                : lorryData?.total_records
                                ? "(" + lorryData?.total_records + ")"
                                : ""
                        }`}
                        showLoadMore={
                            !!(isLoadMkt ? loadMarketResponse?.hasNextPage : lorryMarketResponse?.hasNextPage)
                        }
                        handleClickLoadMore={() => {
                            wrapActionWithLoginModal(
                                isLoadMkt ? loadMarketResponse?.fetchNextPage : lorryMarketResponse?.fetchNextPage
                            );
                        }}
                        loadingState={!!(isLoadMkt ? loadMarketResponse?.isFetching : lorryMarketResponse?.isFetching)}
                    >
                        {!isPhoneVerified && (
                            <div className={Styles.shortDesc}>
                                <ShowMoreText
                                    limit={
                                        isBot
                                            ? getMktContentByCity(cityMeta.IN_CITY_TEXT_WITHOUT_INDIA, isLoadMkt)
                                                  .titleH1Desc.length
                                            : isMobileScreen
                                            ? 40
                                            : 100
                                    }
                                    truncatedEnding=""
                                    more={<Typography weight="semibold">Read More +</Typography>}
                                    text={
                                        getMktContentByCity(cityMeta.IN_CITY_TEXT_WITHOUT_INDIA, isLoadMkt).titleH1Desc
                                    }
                                />
                            </div>
                        )}

                        <div className={Styles.listItems}>
                            {isLoadMkt && (
                                <>
                                    {!!loadData?.load_cards?.length ? (
                                        <>
                                            {loadData?.load_cards.map((load: LoadMarketCard, index) => {
                                                if (load === undefined) return;

                                                const isNudgePositionLast =
                                                    !!loadData?.load_cards.length &&
                                                    loadData?.load_cards.length <= NudgeCardPosition &&
                                                    loadData?.load_cards.length === index + 1;

                                                if (
                                                    (index === NudgeCardPosition || isNudgePositionLast) &&
                                                    !isCsDashboardUser
                                                ) {
                                                    return (
                                                        <>
                                                            {!isNudgePositionLast ? (
                                                                <MarketplacePostingNudgeCard type="lorry" />
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <MarketplaceLoadCard
                                                                key={index}
                                                                id={`${IDs.marketPlace.marketPlaceCard}__${index}`}
                                                                data={load}
                                                                onBidNowClick={() => {
                                                                    onClickLoadBid(load, index);
                                                                    trackLoadBid(
                                                                        GA4EventNames.MARKET_PLACE["book_now"],
                                                                        load,
                                                                        index + 1
                                                                    );
                                                                    setTrackingDataForSelectedLoad({
                                                                        load: load,
                                                                        index: index + 1
                                                                    });
                                                                }}
                                                                handleClickProfile={() => {
                                                                    trackLoadBid(
                                                                        GA4EventNames.MARKET_PLACE[
                                                                            "view_profile_clicked"
                                                                        ],
                                                                        load,
                                                                        index,
                                                                        {
                                                                            from_screen: "load_market",
                                                                            profile_verified:
                                                                                load?.load_detail?.is_verified
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                            {isNudgePositionLast ? (
                                                                <MarketplacePostingNudgeCard type="lorry" />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    );
                                                }
                                                return (
                                                    <MarketplaceLoadCard
                                                        key={index}
                                                        id={`${IDs.marketPlace.marketPlaceCard}__${index}`}
                                                        data={load}
                                                        onBidNowClick={() => {
                                                            onClickLoadBid(load, index);
                                                            trackLoadBid(
                                                                GA4EventNames.MARKET_PLACE["book_now"],
                                                                load,
                                                                index + 1
                                                            );
                                                            setTrackingDataForSelectedLoad({
                                                                load: load,
                                                                index: index + 1
                                                            });
                                                        }}
                                                        handleClickProfile={() => {
                                                            trackLoadBid(
                                                                GA4EventNames.MARKET_PLACE["view_profile_clicked"],
                                                                load,
                                                                index,
                                                                {
                                                                    from_screen: "load_market",
                                                                    profile_verified: load?.load_detail?.is_verified
                                                                }
                                                            );
                                                        }}
                                                    />
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            {loadMarketResponse?.isFetching || shouldExecuteApi === undefined ? (
                                                LoadLorrySkeletonList
                                            ) : (
                                                <NoActiveLoadsLorries
                                                    title=""
                                                    description="Couldn't find a matching load. Post your lorry now to receive instant notifications when matching loads are available!"
                                                    image={`transport-profile-nudges/trucker.png`}
                                                    width="183"
                                                    height="100"
                                                    ctaAction={() => {
                                                        trackMarketPlaceEvent(
                                                            GA4EventNames.MARKET_PLACE["add_lorry_attach_lorry_btn"],
                                                            {
                                                                from_screen: "empty_marketplace"
                                                            }
                                                        );
                                                        ValidateLorryPosting(() => {
                                                            loadLorryPostingConfig?.updateLoadLorryPostingData({
                                                                isModalOpen: true,
                                                                category: "post",
                                                                originationPage:
                                                                    POSTING_FORM_MODAL_ORIGINATION_PAGE.MARKETPLACE,
                                                                type: "lorry"
                                                            });
                                                            loadLorryPostingConfig?.openLorryPostingModal(
                                                                LORRY_CREATION_FORM_IDS.VEHICLE_NUMBER
                                                            );
                                                        });
                                                    }}
                                                    {...(!isCsDashboardUser &&
                                                        !isTso && {
                                                            ctaText: "Post Lorry"
                                                        })}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {isLorryMkt && (
                                <>
                                    {!!lorryData?.lorry_cards?.length ? (
                                        <>
                                            {lorryData?.lorry_cards?.map((lorry: LorryCardV2, index) => {
                                                if (lorry === undefined) {
                                                    return;
                                                }

                                                const isNudgePositionLast =
                                                    !!lorryData?.lorry_cards.length &&
                                                    lorryData?.lorry_cards.length <= NudgeCardPosition &&
                                                    lorryData?.lorry_cards.length === index + 1;

                                                if (
                                                    (index === NudgeCardPosition || isNudgePositionLast) &&
                                                    !isCsDashboardUser
                                                ) {
                                                    return (
                                                        <>
                                                            {!isNudgePositionLast ? (
                                                                <MarketplacePostingNudgeCard type="load" />
                                                            ) : (
                                                                <></>
                                                            )}
                                                            <MarketplaceLorryCard
                                                                key={lorry?.lorry_detail?.id}
                                                                data={lorry}
                                                                id={`lorry-mkt-${index}`}
                                                                onBidNowClick={() => {
                                                                    onClickLorryBid(lorry, index);
                                                                }}
                                                                trackOnClickBidNow={() => {
                                                                    trackLorryBid(
                                                                        GA4EventNames.MARKET_PLACE["book_now"],
                                                                        lorry,
                                                                        index
                                                                    );
                                                                    setTrackingDataForSelectedLorry({
                                                                        lorry: lorry,
                                                                        index: index + 1
                                                                    });
                                                                }}
                                                                handleClickProfile={() => {
                                                                    trackLorryBid(
                                                                        GA4EventNames.MARKET_PLACE[
                                                                            "view_profile_clicked"
                                                                        ],
                                                                        lorry,
                                                                        index,
                                                                        {
                                                                            from_screen: "lorry_market",
                                                                            profile_verified:
                                                                                lorry?.lorry_detail?.is_verified
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                            {isNudgePositionLast ? (
                                                                <MarketplacePostingNudgeCard type="load" />
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    );
                                                }

                                                return (
                                                    <MarketplaceLorryCard
                                                        key={lorry?.lorry_detail?.id}
                                                        data={lorry}
                                                        id={`lorry-mkt-${index}`}
                                                        onBidNowClick={() => {
                                                            onClickLorryBid(lorry, index);
                                                        }}
                                                        trackOnClickBidNow={() => {
                                                            trackLorryBid(
                                                                GA4EventNames.MARKET_PLACE["book_now"],
                                                                lorry,
                                                                index
                                                            );
                                                            setTrackingDataForSelectedLorry({
                                                                lorry: lorry,
                                                                index: index + 1
                                                            });
                                                        }}
                                                        handleClickProfile={() => {
                                                            trackLorryBid(
                                                                GA4EventNames.MARKET_PLACE["view_profile_clicked"],
                                                                lorry,
                                                                index,
                                                                {
                                                                    from_screen: "lorry_market",
                                                                    profile_verified: lorry?.lorry_detail?.is_verified
                                                                }
                                                            );
                                                        }}
                                                    />
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            {lorryMarketResponse?.isFetching || shouldExecuteApi === undefined ? (
                                                LoadLorrySkeletonList
                                            ) : (
                                                <NoActiveLoadsLorries
                                                    title=""
                                                    description="Couldn't find a matching lorry. Post your load now to receive instant notifications when matching lorries are available!"
                                                    image={`/transport-profile-nudges/shipper.png`}
                                                    width="200"
                                                    height="175"
                                                    unoptimized
                                                    ctaAction={() => {
                                                        trackMarketPlaceEvent(
                                                            GA4EventNames.MARKET_PLACE["post_load_button_click"],
                                                            {
                                                                from_screen: "empty_marketplace"
                                                            }
                                                        );
                                                        ValidateLoadPosting(() => {
                                                            setTimeout(() => {
                                                                loadLorryPostingConfig?.openLoadPostingModal(
                                                                    LOAD_CREATION_FORM_IDS.LOAD_INFO
                                                                );
                                                                loadLorryPostingConfig?.updateLoadLorryPostingData({
                                                                    isModalOpen: true,
                                                                    category: "post",
                                                                    originationPage:
                                                                        POSTING_FORM_MODAL_ORIGINATION_PAGE.MARKETPLACE,
                                                                    type: "load"
                                                                });
                                                            }, 1000);
                                                        });
                                                    }}
                                                    {...(!isCsDashboardUser &&
                                                        !isTso && {
                                                            ctaText: "Post Load"
                                                        })}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </ListContainer>

                    {!isPhoneVerified && isMkt && (
                        <>
                            <div className={Styles.miscContent}>
                                <VahakBenefits
                                    city={cityMeta.CITY_TEXT_WITHOUT_INDIA}
                                    activeMarket={activeMarketType}
                                />
                                <ContentSection
                                    content={
                                        getMktContentByCity(cityMeta.IN_CITY_TEXT_WITHOUT_INDIA, isLoadMkt).content
                                    }
                                />
                                <FaqSection
                                    faqList={
                                        getMktContentByCity(cityMeta.IN_CITY_TEXT_WITHOUT_INDIA, isLoadMkt).faqList
                                    }
                                />
                            </div>
                        </>
                    )}
                </div>

                {isLoadMkt && (
                    <LoadBidFormNew
                        lorryAlreadySelected={
                            {
                                lorryNum: marketplaceConfig?.selectLorryNumber ?? "",
                                lorryId: marketplaceConfig?.selectedLorryId ?? 0
                            } as any
                        }
                        trackEvent={(name) => {
                            trackLoadBid(
                                name,
                                trackingDataForSelectedLoad?.load || ({} as any),
                                trackingDataForSelectedLoad?.index || 0
                            );
                        }}
                    />
                )}

                {isLorryMkt && bidFormConfig.lorry.open && (
                    <LorryBidForm
                        lorryDetails={selectedLorry?.lorry_detail || bidFormConfig.lorry.data.lorry_detail}
                        onSuccess={(name, data) => {
                            (trackingDataForSelectedLorry?.lorry ||
                                bidFormConfig.lorry.data ||
                                selectedLorry?.lorry_detail) &&
                                trackLorryBid(
                                    name,
                                    trackingDataForSelectedLorry?.lorry || bidFormConfig.lorry.data || selectedLorry,
                                    trackingDataForSelectedLorry?.index || 0,
                                    data
                                );
                        }}
                    />
                )}
            </div>
            {!isPhoneVerified && !requiredCompanyId && (
                <MandateServiceType isLoggedIn={isLoggedIn} initialServiceType={serviceType} source="marketplace" />
            )}
        </>
    );
};

export default MarketPlacePage;

const LoadLorrySkeletonList = Array(6)
    .fill(0)
    .map((_, ix) => {
        return <MarketplaceLoadCardShimmer key={ix} />;
    });

export const getLoadTrackData = (ld: LoadMarketCard) => {
    const { load_detail, company_detail } = ld;
    const requiredLorry = NEW_LORRY_TYPES.find(({ id }) => id === load_detail?.lorry_type_id);

    const loadInfo = {
        load_id: load_detail?.id,
        is_load_verified: load_detail?.is_verified,
        load_type: load_detail?.is_tap_n_go ? "tap_n_go" : "general",
        from_location: [load_detail?.source_city_name, load_detail?.source_state_name].join(","),
        to_location: [load_detail?.destination_city_name, load_detail?.destination_state_name].join(","),
        material: load_detail?.material_type,
        quantity: load_detail?.quantity,
        quantity_type: LORRY_CATEGORIES_ENUM.TANKER === load_detail?.lorry_type_id ? "kilo_liters" : "tonnes",
        required_vehicle_type: requiredLorry?.label,
        required_body_type: load_detail?.body_type === TRUCK_BODY_TYPES.CLOSED ? "open_full_body" : "open_half_body",
        required_tyres: load_detail?.total_tyres,
        loading_time: load_detail?.loading_timestamp,
        recommended_price: null,
        expected_price: load_detail?.expected_amount,
        expected_price_type: stringOperations(EXPECTED_AMOUNT_TYPE[load_detail?.expected_type], [
            "toLowerCase",
            "replaceSpacesWithUnderscore"
        ]),
        payment_type: stringOperations(PAYMENT_TYPE_MAP[load_detail?.payment_type], [
            "toLowerCase",
            "replaceSpacesWithUnderscore"
        ]),
        advance_percentage: load_detail?.advance_payment_percentage,
        odc: load_detail?.is_odc,
        odc_length: load_detail?.length,
        odc_breadth: load_detail?.breadth,
        odc_height: load_detail?.height
    };

    const profileInfo = {
        profile_user_id: null,
        profile_user_name: null,
        profile_company_id: company_detail?.id,
        profile_company_name: company_detail?.name,
        profile_phone_number: null,
        profile_service_type: company_detail?.service_type,
        profile_location: company_detail?.home_city_name,
        total_connections: null
    };
    return {
        ...loadInfo,
        ...profileInfo
    };
};

export const getLorryTrackData = (lr: LorryCardV2) => {
    const { lorry_detail, company_detail } = lr;

    const isAip = lorry_detail?.total_routes === STATE_LIST.length;
    const routes = isAip
        ? "All India Permit"
        : STATE_LIST.filter((state) => lorry_detail?.routes?.some(({ id }) => id === state.id))?.map(
              (state) => state.code
          );
    const lorryInfo = {
        lorry_id: lorry_detail?.id,
        is_lorry_verified: lorry_detail?.is_verified,
        vehicle_number: lorry_detail?.number,
        current_location: [lorry_detail?.source_info.city_name, lorry_detail?.source_info.state_name].join(","),
        routes: routes,
        total_route: lorry_detail?.total_routes,
        lorry_type: lorry_detail?.lorry_type,
        lorry_capacity: lorry_detail?.capacity,
        capacity_type: LORRY_CATEGORIES_ENUM.TANKER === lorry_detail?.type_value ? "kilo_liters" : "tonnes",
        total_tyres: lorry_detail?.total_tyres,
        body_type: lorry_detail?.body_type === TRUCK_BODY_TYPES.CLOSED ? "open_full_body" : "open_half_body",
        vehicle_size: lorry_detail?.size,
        vehicle_size_type: lorry_detail?.size_text
    };

    const profileInfo = {
        profile_user_id: null,
        profile_user_name: null,
        profile_company_id: company_detail?.id,
        profile_company_name: company_detail?.name,
        profile_phone_number: null,
        profile_service_type: company_detail?.service_type,
        profile_location: company_detail?.home_city_name,
        total_connections: null
    };
    return {
        ...lorryInfo,
        ...profileInfo
    };
};
