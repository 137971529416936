import { ClientHookOptions, useVahakQuery } from "@vahak/core/dist/hooks/useVahakQueryClient";

import API from "@vahak/core/dist/_services/const";
import { QueryNames } from "@vahak/core/dist/_services/hooks/queryNames";

import useVahakHttpClient from "@vahak/core/dist/hooks/useVahakHttpClient";
import { generateUrlParams } from "@vahak/core-ui/dist/methods/generateUrlParams";

interface useGetPriceRecommendationResponse {
    data?: PriceRecommendation;
}
export interface PriceRecommendation {
    price: number;
    extra_price: number;
    maximum_price: number;
    minimum_price: number;
    can_form_spot_load: boolean;
    can_form_tap_n_go_load: boolean;
    max_expected_price?: number;
    min_expected_price?: number;
}

interface GetPriceRecommendationRequest {
    lorry_type_id?: number;
    lorry_capacity?: number;
    source_place_id?: string;
    destination_place_id?: string;
    source_id?: number;
    destination_id?: number;
    size?: number;
    c_i?: number;
}

export const useGetPriceRecommendation = (
    params: GetPriceRecommendationRequest,
    options: ClientHookOptions<useGetPriceRecommendationResponse, unknown, useGetPriceRecommendationResponse["data"]>
) => {
    const { GET } = useVahakHttpClient();

    const getVASPlan = () => {
        const urlString = generateUrlParams(params);

        return GET<useGetPriceRecommendationResponse>(`${API.PRICE_RECOMMENDATION}${urlString}`);
    };

    return useVahakQuery<useGetPriceRecommendationResponse, unknown, useGetPriceRecommendationResponse["data"]>(
        [QueryNames.priceRecommendation, params],
        getVASPlan,
        {
            ...options,
            select: (response) => {
                return response?.data;
            },
            enabled:
                !!(params.destination_place_id || params.destination_id) &&
                !!(params.source_place_id || params.source_id) &&
                options.enabled
        }
    );
};
